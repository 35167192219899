<template>
  <header class="header">
    <RouterLink to="/" class="logo-link">
      <p>EVGENIY PAVLOV</p>
    </RouterLink>
    <div class="segment">
      <p>KHARKIV UKRAINE</p>
      <p>1949 – ONGOING</p>
      <p>1972-2004 YEARS ACTIVE</p>
      <p>24 SERIES</p>
      <p>3415 ARTWORKS</p>
    </div>
    <button class="burger-menu" @click="toggleMenu">☰</button>
    <nav class="menu" :class="{ 'is-active': isMenuActive }">
      <button class="close-menu" @click="hideMenu">✕</button>
      <ul>
        <li><RouterLink to="/exhibitions" @click.native="hideMenu">EXHIBITIONS</RouterLink></li>
        <li><RouterLink to="/index" @click.native="hideMenu">INDEX</RouterLink></li>
        <li><RouterLink to="/press" @click.native="hideMenu">PRESS</RouterLink></li>
        <li><RouterLink to="/contact" @click.native="hideMenu">CONTACT</RouterLink></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      isMenuActive: false
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    hideMenu() {
      this.isMenuActive = false;
    }
  }
};
</script>
