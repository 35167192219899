<template>
  <div class="app-container">
    <Header />
    <main class="main-content">
      <router-view/>
    </main>
  </div>
</template>

<script>
import Header from './components/Header.vue';

export default {
  components: {
    Header
  },
};
</script>
<style>
/* Import global styles */
@import './assets/styles/styles.scss';


.app-container {
  display: grid;
  grid-template-rows: auto 1fr; /* Header auto-height, content takes the rest */
  min-height: 100vh;
}

.main-content {
  overflow: auto; /* Ensure the content is scrollable */
}
</style>
